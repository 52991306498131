
//Dev config
const dev = {
    API_ENDPOINT: 'http://localhost:5104/api',
    API_STATISTICS_ENDPOINT: 'http://localhost:5016/api'
};

    
//Prod config
const prod = {
    API_ENDPOINT:  'https://laddvisapi.azurewebsites.net/api',
    API_STATISTICS_ENDPOINT: 'https://laddvisstatisticsapi-esb4bfetcsfwgcbk.westeurope-01.azurewebsites.net/api'

};

//Change between dev and prod as needed
const config = prod;

export default config;