import React, { useState } from 'react';
import { Container, Form, Button, Alert, Modal, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import config from '../config';

function DeleteAccount() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [isAccountDeleted, setIsAccountDeleted] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowConfirmModal(true);
    };

    const confirmDelete = async () => {
        setShowConfirmModal(false);
        setMessage('');
        setIsError(false);
        try {
            const response = await axios.post(`${config.API_ENDPOINT}/User/delete`, {
                emailIsVerified: true,
                email,
                password,
                notificationToken: '123' // Assuming this is not available in the current scope
            });

            if (response.status === 200) {
                setMessage('Ditt konto har raderats permanent.');
                setIsAccountDeleted(true);
            } else {
                setIsError(true);
                setMessage('Kunde inte radera kontot. Kontrollera att e-postadressen och lösenordet är korrekta.');
            }
        } catch (error) {
            setIsError(true);
            setMessage('Ett fel uppstod. Försök igen senare.');
        }
    };

    return (
        <Container className="mt-5">
            <Row className="justify-content-md-center">
                <Col md={6}>
                    <h1>Radera konto</h1>
                    <p>Varning: Detta kommer att permanent radera ditt konto och all tillhörande information. Denna åtgärd kan inte ångras.</p>
                    
                    {!isAccountDeleted && (
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3 text-center">
                                <Form.Label>E-postadress</Form.Label>
                                <Form.Control 
                                    type="email" 
                                    value={email} 
                                    onChange={(e) => setEmail(e.target.value)} 
                                    required 
                                    style={{ border: '2px solid #ced4da', maxWidth: '300px', margin: '0 auto' }}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3 text-center">
                                <Form.Label>Lösenord</Form.Label>
                                <Form.Control 
                                    type="password" 
                                    value={password} 
                                    onChange={(e) => setPassword(e.target.value)} 
                                    required 
                                    style={{ border: '2px solid #ced4da', maxWidth: '300px', margin: '0 auto' }}
                                />
                            </Form.Group>

                            <Button variant="danger" type="submit">
                                Radera mitt konto
                            </Button>
                        </Form>
                    )}

                    {message && (
                        <Alert variant={isError ? 'danger' : 'success'} className="mt-3">
                            {message}
                        </Alert>
                    )}

                    <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Bekräfta radering av konto</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Är du säker på att du vill radera ditt konto permanent? Denna åtgärd kan inte ångras.</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
                                Avbryt
                            </Button>
                            <Button variant="danger" onClick={confirmDelete}>
                                Ja, radera mitt konto
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Col>
            </Row>
        </Container>
    );
}

export default DeleteAccount;
