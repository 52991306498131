import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, InputGroup, FormControl } from 'react-bootstrap';
import TimePicker from 'react-time-picker';
import DatePicker from 'react-datepicker';
import config from '../config';

function SavingsSimulator() {
  const [batteryCapacity, setBatteryCapacity] = useState('');
  const [chargePercentage, setChargePercentage] = useState(80);
  const [maxChargingPower, setMaxChargingPower] = useState('');
  const [selectedDays, setSelectedDays] = useState(['Mon', 'Tue', 'Wed', 'Thu', 'Fri']);
  const [electricityArea, setElectricityArea] = useState('SE1');
  const [chargingTime, setChargingTime] = useState('18:00');
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [simulationResults, setSimulationResults] = useState(null);

  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  useEffect(() => {
    validateInputs();
  }, [batteryCapacity, chargePercentage, maxChargingPower, selectedDays, electricityArea, chargingTime]);

  const validateInputs = () => {
    const isValid = batteryCapacity !== '' && maxChargingPower !== '' && selectedDays.length > 0;
    setIsButtonEnabled(isValid);
  };

  const toggleDay = (day) => {
    setSelectedDays(prevDays => {
      if (prevDays.includes(day)) {
        return prevDays.filter(d => d !== day);
      } else {
        return [...prevDays, day];
      }
    });
  };

  const selectAllDays = () => setSelectedDays([...days]);
  const selectWeekdays = () => setSelectedDays(['Mon', 'Tue', 'Wed', 'Thu', 'Fri']);

  const getDaysZeroString = () => {
    return days.map(day => selectedDays.includes(day) ? '1' : '0').join('');
  };

  const handleCalculate = async () => {
    // Implement calculation logic here
    // For now, we'll just set some dummy results
    
    const response = await fetch(config.API_STATISTICS_ENDPOINT + '/SavingsSimulator/getLastMonth?batteryCapacity=' + batteryCapacity + '&chargePercentage=' + chargePercentage + '&maxChargingPower=' + maxChargingPower + '&selectedDays=' + getDaysZeroString() + '&electricityArea=' + electricityArea + '&chargingTime=' + chargingTime);
    const data = await response.json();
    setSimulationResults(data);

    // setSimulationResults({
    //   totalSavings: 100,
    //   days: [
    //     { date: new Date(), smartCharging: 10, regularCharging: 15, savings: 5, charging: true },
    //     { date: new Date(), charging: false },
    //   ]
    // });
  };

  const handleTimeChange = (date) => {
    setChargingTime(date);
  };

  const handleChargePercentageChange = (value) => {
    const newValue = Math.min(Math.max(value, 1), 100);
    setChargePercentage(newValue);
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={6}>
          <div style={{ maxWidth: '600px', margin: '0 auto', textAlign: 'left' }}>
            <h1>Se hur mycket du kan spara</h1>
            <p>Vi behöver veta lite om när och hur du laddar. Fyll i värdena i formuläret nedan så räknar Laddvis fram hur mycket du skulle kunna spara på att använda appen</p>

            <Form>
              <h2>Batteriegenskaper</h2>
              <Form.Group>
                <Form.Label>Batterikapacitet (kWh)</Form.Label>
                <Form.Control
                  type="number"
                  value={batteryCapacity}
                  onChange={(e) => setBatteryCapacity(e.target.value)}
                  placeholder="Ange batterikapacitet"
                />
              </Form.Group>

              <br />

              <Form.Group>
                <Form.Label>Laddning per dag (%)</Form.Label>
                <div className="d-flex align-items-center">
                  <Form.Control
                    type="range"
                    min="1"
                    max="100"
                    value={chargePercentage}
                    onChange={(e) => handleChargePercentageChange(Number(e.target.value))}
                    className="mr-3"
                    style={{width: '70%'}}
                  />
                  <InputGroup style={{width: '30%'}}>
                    <Form.Control
                      type="number"
                      value={chargePercentage}
                      onChange={(e) => handleChargePercentageChange(Number(e.target.value))}
                      min="1"
                      max="100"
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                </div>
              </Form.Group>

              <br />

              <Form.Group>
                <Form.Label style={{ display: 'block', padding: '10px', borderRadius: '5px', backgroundColor: '#f8f9fa' }}>
                  Daglig laddning: {((batteryCapacity * (100 - chargePercentage)) / 100).toFixed(2)} kWh
                </Form.Label>
              </Form.Group>

              <br />

              <Form.Group>
                <Form.Label>Max laddeffekt (W)</Form.Label>
                <Form.Control
                  type="number"
                  value={maxChargingPower}
                  onChange={(e) => setMaxChargingPower(e.target.value)}
                  placeholder="Ange max laddeffekt"
                />
              </Form.Group>

              <br />

              <h2>När laddar du bilen</h2>
              <Button variant="link" onClick={selectAllDays}>Alla dagar</Button>
              <Button variant="link" onClick={selectWeekdays}>Vardagar</Button>

              {days.map(day => (
                <Form.Check
                  key={day}
                  type="checkbox"
                  label={day}
                  checked={selectedDays.includes(day)}
                  onChange={() => toggleDay(day)}
                />
              ))}
              <br />

              <h2>Elområde</h2>
              <Form.Group>
                <Form.Control
                  as="select"
                  value={electricityArea}
                  onChange={(e) => setElectricityArea(e.target.value)}
                >
                  <option value="SE1">Elområde 1 - Luleå / SE1 / Norra Sverige</option>
                  <option value="SE2">Elområde 2 - Sundsvall / SE2 / Mellansverige</option>
                  <option value="SE3">Elområde 3 - Stockholm / SE3 / Södra Mellansverige</option>
                  <option value="SE4">Elområde 4 - Malmö / SE4 / Södra Sverige</option>
                </Form.Control>
              </Form.Group>

              <br />

              <h2>Vilken tid börjar du ladda bilen idag</h2>
              <div style={{ width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Form.Control
                    type="number"
                    min="0"
                    max="23"
                    value={chargingTime.split(':')[0]}
                    onChange={(e) => {
                      const newTime = `${e.target.value.padStart(2, '0')}:${chargingTime.split(':')[1]}`;
                      setChargingTime(newTime);
                    }}
                    placeholder="HH"
                    style={{ width: '80px', marginRight: '10px' }}
                  />
                  <span>:</span>
                  <Form.Control
                    type="number"
                    min="0"
                    max="59"
                    value={chargingTime.split(':')[1]}
                    onChange={(e) => {
                      const newTime = `${chargingTime.split(':')[0]}:${e.target.value.padStart(2, '0')}`;
                      setChargingTime(newTime);
                    }}
                    placeholder="mm"
                    style={{ width: '50px', marginLeft: '10px' }}
                  />
                </div>
              </div>

              <br />

              <div className="d-flex align-items-center mt-3">
                <Button
                  onClick={handleCalculate}
                  disabled={!isButtonEnabled}
                >
                  Beräkna
                </Button>
                {!isButtonEnabled && (
                  <span className="ms-2 text-danger">Fyll i alla värden i formuläret för att kunna beräkna besparingar</span>
                )}
              </div>
            </Form>

            {simulationResults && (
              <div className="mt-4">
                <h2>Besparingar</h2>
                <p>Här visas för varje dag under förra månaden hur mycket smartladdning med Laddvis skulle kostat jämfört med att starta laddningen en fast tidpunkt varje dag.*</p>
                <div className="bg-primary text-white p-2 mb-3">
                  Total besparing: {simulationResults.totalSavings} kr
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Dag</th>
                      <th>Laddvis</th>
                      <th>Vanlig</th>
                      <th>Besparing</th>
                    </tr>
                  </thead>
                  <tbody>
                    {simulationResults.days.map((day, index) => (
                      <tr key={index}>
                        <td>{new Date(day.date).getDate()}</td>
                        {day.charging ? (
                          <>
                            <td>{day.smartCharging.toFixed(2)}kr</td>
                            <td>{day.regularCharging.toFixed(2)}kr</td>
                            <td>{day.savings.toFixed(2)}kr</td>
                          </>
                        ) : (
                          <td colSpan="3">Ingen laddning den här dagen</td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <p className="mt-3">
                  * Siffrorna är uppskattningar baserade på de uppgifter du har angett.
                  Laddvis beräkning utgår från att appen används för att ladda bilen de billigaste timmarna från kl 18.00 på kvällen till 08:00 på morgonen.
                  Den vanliga laddningen utgår från att laddningen startas en fast tidpunkt varje dag. Den tidpunkt du väljer i formuläret ovan.
                </p>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default SavingsSimulator;