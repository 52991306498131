import React, { useRef, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Routes, Route, Link, NavLink, useLocation } from "react-router-dom";
import Home from './views/Home';
import HowItWorks from './views/HowItWorks';
import Contact from './views/Contact';
import VerifyEmail from './views/VerifyEmail';
import ResetPassword from './views/ResetPassword';
import SavingsSimulator from './views/SavingsSimulator';
import DeleteAccount from './views/DeleteAccount';

function App() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  
  const location = useLocation();
  
  useEffect(() => {
    document.title = "Laddvis.se - spara pengar på elbilsladdning";
    document.body.style.backgroundSize = "cover";
    document.querySelector('link[rel="icon"]').href = "./images/logga_mini.png";
  }, []);

  return (
    <div className='App'>
      <nav className="navbar navbar-expand-lg navbar-dark" style={{backgroundColor: '#EF9667'}}>
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src="./images/logga_withtext.png" alt="Laddvis" className="main_logo" style={{maxHeight: '40px', width: 'auto'}} />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <NavLink className="nav-link" exact activeClassName="active" end to="/">
                  {location.pathname === '/' ? '● Hem' : 'Hem'}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/how-it-works" activeClassName="active">
                  {location.pathname === '/how-it-works' ? '● Hur fungerar appen' : 'Hur fungerar appen'}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/contact" activeClassName="active">
                  {location.pathname === '/contact' ? '● Kontakt' : 'Kontakt'}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/savings-simulator" activeClassName="active">
                  {location.pathname === '/savings-simulator' ? '● Se hur mycket du kan spara' : 'Se hur mycket du kan spara'}
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div style={{marginTop: '20px'}}></div>
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/verifyEmail" element={<VerifyEmail />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/savings-simulator" element={<SavingsSimulator />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
        </Routes>
      </div>
      <footer className="mt-5" style={{backgroundColor: '#f8f9fa', padding: '20px 0'}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 mb-3 mb-md-0">
              <Link to="https://play.google.com/store/apps/details?id=se.laddvis" target="_blank" rel="noopener noreferrer" className="custom-link d-flex align-items-center">
                <img src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" alt="Get it on Google Play" style={{height: '40px', width: 'auto', marginRight: '10px'}}/>
                Ladda hem Laddvis
              </Link>
            </div>
            <div className="col-12 col-md-6 text-md-end">
              <div style={{color: '#3d3d3d'}}>
                &copy; 2023 Onytec AB
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
