import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Home() {
  const imageStyle = {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '200px', // Changed width to maxWidth to limit the size and keep proportions
    height: 'auto'
  };

  const ImageRotator = () => (
    <Carousel nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" />} prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon" />} interval={null}>
      {['./images/image1.png', './images/image2.png', './images/image3.png', './images/image4.png', './images/image5.png'].map((src, index) => (
        <Carousel.Item key={src}>          
          <img
            className="d-block"
            src={src}
            alt={`Slide ${index + 1}`}
            style={{ ...imageStyle, maxWidth: '200px' }}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );

  return (
    <Container>
      <Row>
        <Col>
         <h1>Välkommen till Laddvis</h1>
          <p>Vi tillhandahåller appen Laddvis som hjälper dig med timprisavtal på elen att ladda din elbil när det är som allra billigast.</p>
          <p></p>
          <hr/>
          <Col style={{ maxWidth: '400px', margin: '0 auto' }}>
            <ImageRotator />
          </Col>
          <hr/>

          <h2>Ladda hem appen från Play Store:</h2>
          <a href="https://play.google.com/store/apps/details?id=com.laddvis" target="_blank" rel="noopener noreferrer">
            <img src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" alt="Get it on Google Play" style={{height: '60px', width: 'auto'}}/>
          </a>
          <hr/>
          
          {/* New banner */}
          <Link to="/savings-simulator" style={{ textDecoration: 'none' }}>
            <div style={{
              backgroundColor: '#EF9667',
              color: 'white',
              padding: '20px',
              borderRadius: '10px',
              marginBottom: '20px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              transition: 'transform 0.3s ease-in-out',
            }}
            onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.02)'}
            onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
            >
              <h3 style={{ margin: 0 }}>Räkna på hur mycket du kan spara på att använda Laddvis</h3>
              <p style={{ margin: '10px 0 0 0' }}>Klicka här för att se din potentiella besparing!</p>
            </div>
          </Link>

        </Col>
      </Row>
    </Container>
  );
}

export default Home;

